import { getData } from '../util/script_data.js';

export function getUserInfoSync() {
  return getData('user-info') || {};
}

export function hasPaidCreativeCloud() {
  const userInfo = getUserInfoSync();
  return userInfo.hasPaidCreativeCloud;
}
